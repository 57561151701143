import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useCompletePaymentContentManager } from 'ActionsContentManager/CompletePayment'
import { usePendingPlanContentManager } from 'ActionsContentManager/PendingPlan'
import { useStartSignPlanContentManager } from 'ActionsContentManager/SignPlan'
import { Tag } from 'DesignSystem'
import type { SubscriptionResponseI } from 'Pages/Privates/Subscription/Subscription.page'
import styled from 'styled-components/macro'
import useSWR from 'swr'

import { fetcher } from 'Hooks/API/fetcher'
import useUser from 'Hooks/API/useUser.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

const TagWithoutHover = styled(Tag)``
const TagWithHover = styled(Tag)``

const TagStartTrial = styled(Tag)`
  cursor: pointer;
`

const HoverS = styled.div<{hasCursor?: boolean}>`
  ${({ hasCursor }) => hasCursor && 'cursor: pointer;'}

  ${TagWithoutHover} {
    visibility: visible;
    display: flex;
  }

  ${TagWithHover} {
    display: none;
    visibility: hidden;
  }

  :hover {
    ${TagWithHover} {
      display: flex;
      visibility: visible;
    }

    ${TagWithoutHover} {
      display: none;
      visibility: hidden;
    }
  }

`

function TrialPeriodTag () {
  const intl = useIntlShort()
  const { data: user, isLoading } = useUser()
  const { start, content } = useStartSignPlanContentManager()
  const [isStartedSignPlan, setIsStartedSignPlan] = useState(false)
  const { start: startPendingPlan, content: contentPedingPlan } = usePendingPlanContentManager()
  const { start: startCompletePayment, content: contentCompletePayment } = useCompletePaymentContentManager()
  const isHome = !!useRouteMatch(generateLinkTo('home'))
  const { data: dataSubscription } = useSWR<SubscriptionResponseI>('/subscription', fetcher('GET'))
  const subscriptionIsPending = user?.subscription?.status === 'pending'
  const subscriptionIsActive = user?.subscription?.status === 'active'

  const haveSubscription = !!dataSubscription?.plan?.subscription

  const handleStartSignPlan = (checkCompletePayment = false) => {
    if (haveSubscription) return

    if (checkCompletePayment && user && user.isNewerUserRaw && user.havePricePreChosen) {
      startCompletePayment({
        pricePreChosen: user.pricePreChoosen,
        planId: user.planPreChosen?.id,
        planName: user.planPreChosen?.name,
        amount: user.amountPreChosen,
        interval: user.intervalPreChosen,
        currency: user.currencyPreChosen,
        onChangePlans: () => {
          handleStartSignPlan()
        }
      })
    } else {
      start()
    }
  }

  useEffect(() => {
    try {
      if (isLoading) return

      if (subscriptionIsPending) {
        if (!isHome) startPendingPlan()
        return
      }

      if (user && user.isNewerUserRaw && !isStartedSignPlan && user.havePricePreChosen) {
        startCompletePayment({
          pricePreChosen: user.pricePreChoosen,
          planId: user.planPreChosen?.id,
          planName: user.planPreChosen?.name,
          interval: user.intervalPreChosen,
          amount: user.amountPreChosen,
          currency: user.currencyPreChosen,
          onChangePlans: () => {
            handleStartSignPlan()
          }
        })
        setIsStartedSignPlan(true)
        return
      }

      if (user && user.isNewerUserRaw && !isStartedSignPlan) {
        handleStartSignPlan()
        setIsStartedSignPlan(true)
      }
    } catch (error) {
      console.log(error)
    }
  }, [user, isLoading, dataSubscription, subscriptionIsPending])

  if (isLoading) return null
  if (!user) return null

  let text = `${intl('Teste grátis:')} ${user.daysToExpired} ${intl('dias')}`
  let color : any = 'primary'

  if (user.isOnTrialPeriod && user.isTrialPeriodNextToEnd) {
    color = 'alert'
  }

  if (user.isOnTrialPeriod && user.isTrialPeriodExpired) {
    text = `${intl('Assine já seu plano')}`
    color = 'primary'
  }

  if (haveSubscription && subscriptionIsActive) {
    return null
  }

  if (user.subscription?.status === 'pending') {
    return (
      <>
        <TagStartTrial color={color} onClick={() => handleStartSignPlan(true)}>
          <span className='ms-1'>
            {intl('Processando seu plano')}
          </span>
        </TagStartTrial>
        {content}
        {contentPedingPlan}
      </>
    )
  }

  if (!user?.isOnTrialPeriod) {
    return (
    <>
      <TagStartTrial color={color} onClick={() => handleStartSignPlan(true)}>
        <span className='ms-1'>
          {intl('Inicie seu período de avaliação')}
        </span>
      </TagStartTrial>
      {content}
      {contentPedingPlan}
      {contentCompletePayment}
    </>
    )
  }

  if ((user.isOnTrialPeriod && !user.isTrialPeriodExpired && haveSubscription) || subscriptionIsPending) {
    return (
      <>
        <Tag color={color} onClick={subscriptionIsPending ? () => startPendingPlan() : undefined}>
          <span className='ms-1'>
            {text}
          </span>
        </Tag>
        {content}
        {contentPedingPlan}
        {contentCompletePayment}
      </>
    )
  }


  return (
    <>
      <HoverS hasCursor={!haveSubscription}>
        <TagWithoutHover color={color} onClick={() => handleStartSignPlan(true)}>
          <span className='ms-1'>
            {text}
          </span>
        </TagWithoutHover>
        <TagWithHover color={color} onClick={() => handleStartSignPlan(true)}>
          <span className='ms-1'>
            {intl('Assine já seu plano')}
          </span>
        </TagWithHover>
      </HoverS>
      {content}
      {contentPedingPlan}
      {contentCompletePayment}
    </>
  )
}

export default TrialPeriodTag
